import { PublicEnv } from '@/frontend-env'
import '@/styles/globals.css'
import { trpc } from '@/utils/trpc'
import { ClerkProvider, ClerkProvider as ClerkProviderReact, useAuth, useUser } from '@clerk/nextjs'
import { GoogleTagManager, sendGTMEvent } from '@next/third-parties/google'
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister'
import { QueryClient } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider, Persister } from '@tanstack/react-query-persist-client'
import { httpBatchLink } from '@trpc/react-query'
import { DefaultSeo } from 'next-seo'
import type { AppProps } from 'next/app'
import { Inter } from 'next/font/google'
import { useRouter } from 'next/router'
import Script from 'next/script'
import posthog from 'posthog-js'
import { useState } from 'react'
import { Toaster } from 'sonner'
import { useEffect } from 'react'
import { ReferralContext } from '@/providers/referral'

if (typeof window !== 'undefined') {
  posthog.init(PublicEnv.NEXT_PUBLIC_POSTHOG_KEY as string, {
    api_host: PublicEnv.NEXT_PUBLIC_POSTHOG_HOST,
  })
}

let persister: Persister

const inter = Inter({ subsets: ['latin'], variable: '--font-inter' })

if (typeof window !== 'undefined') {
  persister = createSyncStoragePersister({
    storage: window?.localStorage,
  })
}

function App({ Component, pageProps }: AppProps) {
  const [referral, setReferral] = useState<string | undefined>()
  const [queryClient] = useState(() => new QueryClient())
  const auth = useAuth()

  const { user, isLoaded } = useUser()

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout

    const query = new URLSearchParams(window.location.search)

    ;(window as any).rewardful('ready', function () {
      console.log('rewardful ready')

      setReferral((window as any).Rewardful.referral)
    })

    if (query.get('success')) {
      console.log('Order placed! You will receive an email confirmation.')
    }

    if (query.get('canceled')) {
      console.log('Order canceled -- continue to shop around and checkout when you’re ready.')
    }
  }, [])

  useEffect(() => {
    if (!isLoaded || !user) return

    const createdAtInSeconds = user.createdAt
      ? Math.floor(user.createdAt.getTime() / 1000)
      : undefined

    sendGTMEvent({
      event: 'setUserData',
      user_id: user.id,
      email_address: user.primaryEmailAddress?.emailAddress,
      phone_number: user.primaryPhoneNumber?.phoneNumber,
      full_name: user.fullName,
      first_name: user.firstName,
      last_name: user.lastName,
      is_subscribed: user.publicMetadata.isSubscribed,
      plan_type: user.publicMetadata.planType,
      user_created_at: createdAtInSeconds,
      profile_url: user.imageUrl,
    })
  }, [user])

  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: `${PublicEnv.NEXT_PUBLIC_API_URL}/trpc`,
          // fetch: async (input, init?) => {
          //   const fetch = getFetch()
          //   return fetch(input, {
          //     ...init,
          //     credentials: 'include',
          //   })
          // },
          // You can pass any HTTP headers you wish here
          async headers() {
            const token = await auth.getToken({
              template: PublicEnv.NEXT_PUBLIC_JWT_TEMPLATE,
            })

            return {
              Authorization: 'Bearer ' + token,
            }
          },
        }),
      ],
    })
  )

  return (
    <>
      <DefaultSeo
        defaultTitle="v0 report - automate company reports"
        title="v0 report AI"
        description="Use v0 report to generate company reports automatically"
        openGraph={{
          type: 'website',
          url: 'https://v0.report',
          siteName: 'v0 report',
          description: 'Use v0 report to generate company reports automatically',
          title: 'v0 report - automate company report',
          images: [
            {
              url: 'https://v0.report/images/header-image.png',
              alt: 'v0 report',
              type: 'image/png',
            },
          ],
        }}
      />
      <Toaster richColors />
      <ReferralContext.Provider value={referral}>
        <trpc.Provider client={trpcClient} queryClient={queryClient}>
          <PersistQueryClientProvider
            client={queryClient}
            persistOptions={{
              persister,
            }}
          >
            <ReactQueryDevtools initialIsOpen={false} />
            <main className={`${inter.variable} !font-sans`}>
              <Component {...pageProps} />
            </main>
          </PersistQueryClientProvider>
        </trpc.Provider>
      </ReferralContext.Provider>
      <GoogleTagManager gtmId="GTM-MX5KVVXZ" />
      <Script id="default-c">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}

          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
    
          gtag('consent', 'default', {
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            'analytics_storage': 'granted'
          });
          `}
      </Script>
      <Script src="https://r.wdfl.co/rw.js" data-rewardful="97cfc4"></Script>
      <meta name="seobility" content="493dadeb99a11f7244021ea98ef446e9"></meta>
      <meta
        name="keywords"
        content="automate, company reports, v0 report, reporting tool, business analytics, swot reports, swot maker, swot generator, company analysis"
      />
    </>
  )
}

const YetAnotherWrapper = (props: AppProps) => {
  const { pathname } = useRouter()
  const isPricingPage = pathname.includes('pricing')

  return isPricingPage ? (
    <ClerkProviderReact publishableKey={PublicEnv.NEXT_PUBLIC_CLERK_PUBLISHABLE_KEY}>
      <App {...props} />
    </ClerkProviderReact>
  ) : (
    <ClerkProvider {...props.pageProps}>
      <App {...props} />
    </ClerkProvider>
  )
}

export default YetAnotherWrapper
